/* eslint-disable no-new */

/* global $, google, Backbone, MarkerWithLabel */

export const StoreLocatorView = Backbone.View.extend({
  initialize: function () {
    var $el = this.$el

    if (!$el.length) {
      return
    }

    this.$form = $el.find('form')
    this.$results = $el.find('[data-locator-results]')

    this.$form.find('input[name=city]').on('click', function () {
      $(this).select()
    })

    this.$form.on('submit', this.submit.bind(this))

    this._prefill()
  },

  submit: function () {
    var url = this.$form.attr('action')
    this.$form.find('[type="submit"]').blur()
    this._updateParams()

    $.get(url, {
      city: this._getCity(),
      country: this._getCountry(),
      radius: this._getRadius()
    }).then(function (data) {
      this.$results.show()
      this.$results.html(data)
      this.drawMap()
    }.bind(this))

    return false
  },

  drawMap: function () {
    var $canvas = $('#map-canvas')
    var lat = $canvas.data('store-lat')
    var lon = $canvas.data('store-lon')
    var map = new google.maps.Map(document.getElementById('map-canvas'), {
      zoom: 13,
      center: new google.maps.LatLng(lat, lon)
    })

    this._drawMarkers(map)
  },

  _drawMarkers: function (map) {
    var stores = this._getStores()
    var positions = []
    if (stores) {
      for (var i = stores.length - 1; i >= 0; i--) {
        var store = stores[i]
        /* http://google-maps-utility-library-v3.googlecode.com/svn/tags/markerwithlabel/1.1.9/ */
        new MarkerWithLabel({
          position: new google.maps.LatLng(store.latitude, store.longitude),
          map: map,
          title: store.company,
          labelContent: i + 1,
          labelAnchor: new google.maps.Point(7, -5),
          labelStyle: {
            padding: '5px',
            fontSize: '10px',
            lineHeight: '10px',
            color: '#fff',
            backgroundColor: 'rgba(0,0,0,0.3)',
            borderRadius: '1px'
          }
        })
      }
      stores.forEach(function (store) {
        positions.push(new google.maps.LatLng(store.latitude, store.longitude))
      })
      this._zoomToMarkers(map, positions, 5)
    }
  },

  _zoomToMarkers: function (map, positions, count) {
    var bounds = new google.maps.LatLngBounds()
    positions.forEach(function (position, index) {
      if (index < count) {
        bounds.extend(position)
      }
    })
    map.fitBounds(bounds)
  },

  _prefill: function () {
    var city = this._getParam('city')
    var country = this._getParam('country')

    if (city && country) {
      this.$form.find('input[name=city]').val(city)
      this.$form.find('select[name=country]').val(country)
      this.$form.submit()
    }
  },

  _getParam: function (param) {
    var hash = window.location.hash
    var re = new RegExp(param + '=([äöüÄÖÜß\\w]+)')
    var match = hash.match(re)
    if (match) {
      return match[1]
    }
  },

  _updateParams: function () {
    window.location.hash = '?city=' + this._getCity() +
    '&country=' + this._getCountry()
  },

  _getCountry: function () {
    return this.$form.find('select[name=country]').val()
  },

  _getCity: function () {
    return this.$form.find('input[name=city]').val()
  },

  _getRadius: function () {
    return this.$form.find('select[name=radius]').val()
  },

  _getStores: function () {
    return window.stores
  }
})
