/* global $, _, Backbone */

export const HistoryTimelineView = Backbone.View.extend({
  events: {
    'mouseenter .timeline__years li': 'toggleYear',
    'mouseleave': 'resetYear',
    'mouseenter.caption': 'showCaption',
    'mouseleave.caption': 'hideCaption',
    'click .timeline__years li': 'scrollToYear'
  },

  initialize: function () {
    var year = this.$el
      .find('.timeline__years [data-year-initial]')
      .attr('data-year')

    this.wayPoint = this.$el.offset()
      ? this.$el.offset().top + this.$el.height() : 0

    this.model = new Backbone.Model({
      year: year,
      caption: true
    })

    this.initialYear = year
    this.listenTo(this.model, 'change', this.render)

    $(window).on('scroll', _.bind(this.toggleNav, this))

    this.render()
    this.toggleNav()
  },

  render: function () {
    this._reset()
    this._setContentYear()
    this._setTimelineYear()
    this._toggleCaption()
  },

  toggleYear: function (evt) {
    var $target = $(evt.currentTarget)
    this.model.set({year: $target.attr('data-year')})
  },

  resetYear: function () {
    this.model.set({year: this.initialYear})
  },

  showCaption: function () {
    this.model.set({caption: true})
  },

  hideCaption: function () {
    this.model.set({caption: false})
  },

  scrollToYear: function () {
    var year = this.model.get('year')
    window.location.hash = 'year-' + year
  },

  toggleNav: function () {
    var top = $(window).scrollTop()
    $('[data-timeline-back]').toggle(top > this.wayPoint, 'slow')
  },

  _setContentYear: function () {
    this.$el.find('.timeline__content').attr('data-year', this.model.get('year'))
  },

  _setTimelineYear: function () {
    var $year = this.$el.find('li[data-year="' + this.model.get('year') + '"]')
    $year.addClass('hover')
    $year.find('.caption').show()
  },

  _toggleCaption: function () {
    var $year = this.$el.find('li[data-year="' + this.model.get('year') + '"]')
    $year.find('.caption').toggle(this.model.get('caption'))
  },

  _reset: function () {
    this.$el.find('.timeline__years li.hover').removeClass('hover')
    this.$el.find('.timeline__years li > .caption').hide()
  }
})
