/* eslint-disable no-new */

import { DescTeaserView } from './widgets/desc-teaser.js'
import { HistoryTimelineView } from './widgets/history-timeline.js'
import { MegaMenuView } from './widgets/mega-menu.js'
import { ProductsCollection, ProductsFilterView } from './widgets/products-filter.js'
import { StoreLocatorView } from './widgets/store-locator.js'

(function () {
  'use strict'

  // NOTE: This way of detecting hover support is less than ideal.
  // Many devices support both touch and hover.
  var supportsTouch = (typeof Touch === 'object')
  var supportsHover = !supportsTouch
  var isSmallDevice = window.matchMedia('(max-width: 992px)').matches;

  (function () {
    $(document).ready(function () {
      /* global $ */

      // Toggle dropdown menu on hover
      (function toggleDropdownOnHover () {
        $('[data-toggle=dropdown]').parent().hover(function () {
          if ($(this).is('#product-menu')) {
            return
          }

          // Using $(this).dropdown('toggle') breaks links
          $(this).toggleClass('open')
        })
      })();

      // Disable click on dropdown for non-touch devices
      (function disableClickOnDropdown () {
        $('[data-toggle=dropdown]').on('click.bs.dropdown', function () {
          if (supportsHover && !isSmallDevice) {
            return false
          }
        })
      })()
    })

    // Mega Menu
    $(document).ready(function () {
      // No mega menu on small devices
      if (isSmallDevice) {
        return
      }

      var mouseInsideNav = function (target) {
        var isNav = $(target).closest('.header__nav').length > 0
        var isNavItem = $(target).closest('.header__nav > ul').length > 0

        if (isNav && !isNavItem) {
          return true
        }
      }

      window.productMenuView = new MegaMenuView({
        el: '#product-menu',
        mega: '#product-mega-menu',
        menu: '.mega-menu__menu ul',
        content: '.mega-menu__content',
        mouseInside: mouseInsideNav
      })
    })

    // Scroll back
    $(document).ready(function () {
      var waypoint = 300
      var $back = $('[data-scroll-back]')

      var onScroll = function () {
        $back.toggle($(window).scrollTop() > waypoint, 'slow')
      }

      $(window).on('scroll', onScroll)
      onScroll()
    })

    // Timeline
    $(document).ready(function () {
      window.historyTimelineView = new HistoryTimelineView({el: '#history-timeline'})
    })

    // Products Filter
    $(document).ready(function () {
      var products = new ProductsCollection(window.products)
      window.productsFilterView = new ProductsFilterView({
        el: '#products-filter',
        index: '#products-index',
        collection: products
      })
    })

    // Store locator
    $(document).ready(function () {
      new StoreLocatorView({el: '#store-locator'})
    })

    // Desc Teaser
    $(document).ready(function () {
      $('[data-desc-teaser]').each(function () {
        new DescTeaserView({el: this})
      })
    })

    // Contact form
    $(document).ready(function () {
      var $contact = $('.contact-form__contact, .newsletter-form__form')
      var $confirmation = $('.contact-form__confirmation, .newsletter-form__confirmation')
      var $form = $contact.find('#contact-form, #newsletter-form')
      $form.on('submit', function (evt) {
        $.post($form.attr('action'), $form.serialize()).then(function () {
          $(evt.target).find('[type="submit"]').blur()
          $contact.hide()
          $confirmation.show()
        })
        return false
      })
    })
  })()
})()
