/* global $, _, Backbone */

const ROW_COLUMNS = 5

const propertyLabels = {
  'de': {
    'feature': 'Funktion',
    'material': 'Material',
    'color': 'Farbe / Oberfläche',
    'measurements': 'Maße',
    'technique': 'Technik',
    'beverage': 'Getränke'
  },
  'en': {
    'feature': 'Function',
    'material': 'Material',
    'color': 'Color / surface',
    'measurements': 'Measure',
    'technique': 'Technique',
    'beverage': 'Beverage'
  }
}

const optionLabels = {
  'de': {
    // Feature
    'chilischneider': 'Chilischneider',
    'kräuter_gewürzschneider': 'Kräuter- und Gewürzschneider',
    'pfeffer_salzmühle': 'Pfeffer- oder Salzmühle',
    'zubereitung': 'Zubereitung',
    'aufbewahren': 'Aufbewahren',
    'portionieren': 'Portionieren',
    'verschliessen': 'Verschließen',
    'pfeffer_salz_kippmühle': 'Pfeffer- oder Salz Kippmühle',
    'pfeffer_salz_doppelmühle': 'Pfeffer- und Salz Doppelmühle',
    'schäler': 'Schäler',
    'reibe': 'Reibe',
    'salzmühle': 'Salzmühle',
    'käsemühle': 'Käsemühle',
    'muskatmühle': 'Muskatmühle',
    'teebeutel_presse': 'Teebeutel-Presse',
    'teefilter': 'Teefilter',
    'stövchen': 'Stövchen',
    'teekanne': 'Teekanne',
    'to_go': 'To Go',
    'frischhalten': 'Frischhalten',
    'kühlen': 'Kühlen',
    'ausgiessen': 'Ausgiessen',
    'reinigen': 'Reinigen',
    'öffnen': 'Öffnen',
    'dekantieren': 'Dekantieren',
    'tischschmuck': 'Tischschmuck',
    'tischlicht': 'Tischlicht',
    'tischfeuerzeug': 'Tischfeuerzeug',

    // Material
    'holz': 'Holz',
    'edelstahl': 'Edelstahl',
    'kunststoff': 'Kunststoff',
    'acryl': 'Acryl',
    'glas': 'Glas',
    'silikon': 'Silikon',

    // Color
    'schwarz': 'Schwarz',
    'weiss': 'Weiß',
    'rot': 'Rot',
    'orange': 'Orange',
    'gebürstet': 'Gebürstet',
    'natur': 'Natur',
    'grau': 'Grau',
    'poliert': 'Poliert',
    'grün': 'Grün',
    'gelb': 'Gelb',
    'gold': 'Gold',
    'flieder': 'Flieder',
    'klar': 'Klar',

    // Technik
    'batterie': 'Batterie',
    'wiederaufladbar': 'wiederaufladbar',
    'planet+getriebe': 'Planet+ Getriebe',
    'precise+feineinstellung': 'Precise+ Feineinstellung',

    // Masse
    '<10': '<10cm',
    '>10': '>10cm',
    '>20': '>20cm',
    '>30': '>30cm',
    'flasche': 'Flasche',

    // Getränkesorte
    'sekt_champangner': 'Sekt, Champagner',
    'wein': 'Wein',
    'softgetränk': 'Softgetränk',
    'essig_und_öl': 'Essig&Öl'
  },
  'en': {
    // Feature
    'chilischneider': 'Chilli cutter',
    'kräuter_gewürzschneider': 'Herbs and spice cutter',
    'pfeffer_salzmühle': 'Pepper or salt mill',
    'zubereitung': 'Preparation',
    'aufbewahren': 'Storing',
    'portionieren': 'Portioning',
    'verschliessen': 'Closing',
    'pfeffer_salz_kippmühle': 'Pepper or salt tilt mill',
    'pfeffer_salz_doppelmühle': 'Double mill for pepper and salt',
    'schäler': 'Peeler',
    'reibe': 'Grater',
    'salzmühle': 'Salt mill',
    'käsemühle': 'Cheese grater',
    'muskatmühle': 'Nutmeg mill',
    'teebeutel_presse': 'Tea bag squeezer',
    'teefilter': 'Tea infuser',
    'stövchen': 'Teapot warmer',
    'teekanne': 'Teapot',
    'to_go': 'To Go',
    'frischhalten': 'Keeping fresh',
    'kühlen': 'Cooling',
    'ausgiessen': 'Pouring',
    'reinigen': 'Cleaning',
    'öffnen': 'Cleaning',
    'dekantieren': 'Decanting',
    'tischschmuck': 'Table jewels',
    'tischlicht': 'Table lights',
    'tischfeuerzeug': 'Table lighter',

    // Material
    'holz': 'Wood',
    'edelstahl': 'Stainless steel',
    'kunststoff': 'Plastic',
    'acryl': 'Acrylic',
    'glas': 'Glass',
    'silikon': 'Silicone',

    // Color
    'schwarz': 'Black',
    'weiss': 'White',
    'rot': 'Red',
    'orange': 'Orange',
    'gebürstet': 'Brushed',
    'natur': 'Nature',
    'grau': 'Grey',
    'poliert': 'Polished',
    'grün': 'Green',
    'gelb': 'Yellow',
    'gold': 'Gold',
    'flieder': 'Lilac',
    'klar': 'Clear',

    // Technik
    'batterie': 'Battery',
    'wiederaufladbar': 'rechargeable',
    'planet+getriebe': 'Planet+ gearing system',
    'precise+feineinstellung': 'Precise+ precision grinder setting',

    // Masse
    '<10': '<10cm',
    '>10': '>10cm',
    '>20': '>20cm',
    '>30': '>30cm',
    'flasche': 'Bottle',

    // Getränkesorte
    'sekt_champangner': 'Champagne',
    'wein': 'Wine',
    'softgetränk': 'Soft drink',
    'essig_und_öl': 'Vinegar & oil'
  }
}

const Product = Backbone.Model.extend({})

export const ProductsCollection = Backbone.Collection.extend({
  model: Product,

  getProperties: function () {
    let properties = this._getPropertiesFromCollection()
    properties = this._sortPropertyOptions(properties)
    return properties
  },

  _getPropertiesFromCollection: function () {
    const properties = []
    const locale = this.locale

    this.each(function (product) {
      _.each(product.attributes, function (value, productAttribute) {
        if (!value) {
          return
        }

        if (productAttribute === 'article') {
          return
        }

        let property = _.find(properties, function (property) {
          return property.name === productAttribute
        })

        if (!property) {
          const propertyLabel = (propertyLabels[locale] && propertyLabels[locale][productAttribute]) || productAttribute
          property = {
            name: productAttribute,
            label: propertyLabel,
            options: []
          }
          properties.push(property)
        }

        const options = product.get(productAttribute)
        _.each(options, function (productOption) {
          let option = _.find(property.options, function (option) {
            return option.value === productOption
          })

          if (!option) {
            const productLabel = (optionLabels[locale] && optionLabels[locale][productOption]) || productOption
            option = {
              value: productOption,
              label: productLabel
            }
            property.options.push(option)
          }
        })
      })
    })

    return properties
  },

  _sortPropertyOptions: function (properties) {
    return _.each(properties, function (property) {
      property.options = _.sortBy(property.options, function (option) {
        return option.value
      })
    })
  }
})

export const ProductsFilterView = Backbone.View.extend({
  events: {
    'change form[data-category]': 'onChangeCategory',
    'change form[data-properties]': 'onChangeProperty'
  },

  initialize: function (options) {
    this.$index = $(options.index)
    this.$filter = this.$el.find('[data-container="filter"]')
    this.$message = this.$index.find('[data-message]')
    this.$reset = this.$index.find('[data-reset]')
    this.collection.locale = this.$el.attr('lang') || 'de'
    this.filterProperties = {}
    this._renderFilter()
    this.render()
    this.$reset.on('click', _.bind(this.onReset, this))
  },

  onChangeProperty: function (evt) {
    const $form = $(evt.currentTarget)
    this._setFilter($form.serializeArray())
    this.render()
  },

  onChangeCategory: function (evt) {
    const $option = $(evt.target).find('[value="' + evt.target.value + '"]')
    const target = $option.attr('data-target')
    if (target) {
      document.location = target
    }
  },

  onReset: function (evt) {
    this._reset()
    evt.preventDefault()
  },

  render: function () {
    this._toggleProducts()
    this._toggleOffset()
    this._toggleMessage()
  },

  _toggleProducts: function () {
    const productIds = this._getFilteredProducts().pluck('article')
    this.$index.find('[data-product]').hide()
    _.each(productIds, function (productId) {
      this.$index.find('[data-product="' + productId + '"]').show()
    }, this)
  },

  _toggleOffset: function () {
    this.$index.find('[data-product]').removeClass('offset')
    this.$index.find('[data-product]').filter(':visible').each(function (index) {
      if (index % ROW_COLUMNS === 0) {
        $(this).addClass('offset')
      }
    })
  },

  _toggleMessage: function () {
    if (this._getFilteredProducts().length < 1) {
      this.$message.show()
    } else {
      this.$message.hide()
    }
  },

  _reset: function () {
    this.$el.find('form[data-properties] select').val('')
    this.filterProperties = {}
    this.render()
  },

  _getFilteredProducts: function () {
    const filteredProducts = this.collection.filter(function (product) {
      return _.reduce(this.filterProperties, function (memo, filterOption, filterProperty) {
        return memo && (_.contains(product.get(filterProperty), filterOption))
      }, true)
    }, this)

    return new Backbone.Collection(filteredProducts)
  },

  _setFilter: function (form) {
    _.each(form, function (property) {
      if (property.value === '') {
        delete this.filterProperties[property.name]
      } else {
        this.filterProperties[property.name] = property.value
      }
    }, this)
    return this.filterProperties
  },

  _renderFilter: function () {
    const propertyTemplate = _.template($('#products-filter-property').html())
    this.$filter.empty()
    _.each(this.collection.getProperties(), function (property) {
      this.$filter.append(propertyTemplate({property: property}))
    }, this)
  }
})
