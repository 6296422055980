/* global $, _, Backbone */

var DELAY_SHOW = 100
var DELAY_HIDE = 300

export const MegaMenuView = Backbone.View.extend({
  initialize: function (options) {
    this.menuHtml = this.$el.children('ul').html()
    this.contentHtml = this.$el.find('[data-mega="content"]').html()
    this.mouseInside = options.mouseInside

    this.$mega = $(options.mega)
    this.$menu = this.$mega.find(options.menu)
    this.$content = this.$mega.find(options.content)

    this._toggleConfirm()

    this.$el.on('mouseover', _.bind(this.onShow, this))
    this.$mega.on('mouseenter', 'ul > li', _.bind(this.onShowContent, this))
    this.$mega.on('mouseleave', _.bind(this.onHideContent, this))

    $('body').on('mouseover', _.bind(this.onCancelShow, this))
    $('body').on('mouseover', _.bind(this.onHide, this))
    $('body').on('mouseover', _.bind(this.onCancelHide, this))
  },

  _toggleConfirm: function () {
    var supportsTouch = (typeof Touch === 'object')
    if (supportsTouch) {
      this.$content.addClass('confirm')
      this.$content.on('click', '.caption', function () {
        window.location = $(this).find('a').attr('href')
      })
    }
  },

  onShow: function () {
    var showMega = _.bind(this.showMega, this)

    window.clearTimeout(this.showTimeout)
    this.showTimeout = window.setTimeout(showMega, DELAY_SHOW)
  },

  onCancelShow: function (evt) {
    if (this.visible) {
      return
    }

    if ($(evt.target).closest(this.$el).length) {
      return
    }

    window.clearTimeout(this.showTimeout)
  },

  onHide: function () {
    var hideMega = _.bind(this.hideMega, this)

    window.clearTimeout(this.hideTimeout)
    this.hideTimeout = window.setTimeout(hideMega, DELAY_HIDE)
  },

  onCancelHide: function (evt) {
    var isMega = $(evt.target).closest(this.$mega).length > 0
    var isMenu = $(evt.target).closest(this.$el).length > 0

    if (isMega || isMenu || this.mouseInside(evt.target)) {
      window.clearTimeout(this.hideTimeout)
    }
  },

  onShowContent: function (evt) {
    var $target = $(evt.target).find('a').addBack('a')
    var name = $target.attr('data-toggle') || ''
    this.showContent(name, $target)
  },

  onHideContent: function () {
    this.hideContent()
  },

  showMega: function () {
    if (this.visible) {
      return
    }

    window.clearTimeout(this.hideTimeout)
    this.$el.addClass('hover')
    this.$menu.html(this.menuHtml)
    this.$content.html(this.contentHtml)
    this.$mega.show()
    this.$mega.collapse('show')
    this.showContent()
    this.visible = true
  },

  hideMega: function () {
    if (!this.visible) {
      return
    }

    // Fix non negative height
    this.$mega.one('hidden.bs.collapse', function () {
      $(this).height(0)
      $(this).hide()
    })

    window.clearTimeout(this.showTimeout)
    this.$el.removeClass('hover')
    this.$mega.collapse('hide')
    this.visible = false
  },

  showContent: function (name, $target) {
    if (!name && !$target) {
      $target = this.$menu.find('> li > a').first()
      name = $target.attr('data-toggle')
    }

    this.$mega.find('[data-content]').hide()
    this.$mega.find('[data-content="' + name + '"]').show()
    this.$mega.attr('data-container', name)
    this.$mega.find('a').removeClass('active')
    $target.addClass('active')
  },

  hideContent: function () {
    this.$mega.find('[data-content]').hide()
    this.$mega.attr('data-container', '')
    this.$mega.find('a').removeClass('active')
  }
})
