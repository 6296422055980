/* global $, _, Backbone */

export const DescTeaserView = Backbone.View.extend({
  events: {
    'click [data-desc-toggle]': 'toggle'
  },

  initialize: function () {
    this.hidden = false
    this.render()
  },

  render: function () {
    if (!this.hidden) {
      this.hide()
    } else {
      this.expand()
    }
  },

  hide: function () {
    if (this.hidden) {
      return
    }

    var $descendants = this._getDescendants()
    var contTemplate = _.template($('#desc-teaser-continue').html() || '')
    if ($descendants.length) {
      this.$el.find('[data-desc-toggle]').remove()
      $descendants.hide()
      $descendants.last().after(contTemplate())
    }
  },

  expand: function () {
    if (!this.hidden) {
      return
    }

    var $descendants = this._getDescendants()
    var lessTemplate = _.template($('#desc-teaser-less').html() || '')
    this.$el.find('[data-desc-toggle]').remove()
    $descendants.show()
    $descendants.last().after(lessTemplate())
  },

  _getDescendants: function () {
    var types = this.constructor.TYPES.join(', ')
    var exceptions = this.constructor.EXCEPTIONS.join(', ')
    return this.$el
      .find(types)
      .not(exceptions)
      .slice(this.constructor.COUNT)
  },

  toggle: function () {
    this.hidden = !this.hidden
    this.render()
    return false
  }
}, {
  // Minimum number of visible elements
  COUNT: 1,

  // List of considered elements
  TYPES: ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'ul', 'dl', 'table'],

  // List of exempted elements
  EXCEPTIONS: ['p:first-child', 'ul:first-child']
})
